import React, { FC } from "react"
import { Divider, Heading, HStack, Text } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import PercentageInput from "../../../components/forms/PercentageInput"
import SelectInput from "../../../components/forms/SelectInput"
import WizardNavigateNextButton from "../../../components/WizardNavigateNextButton"
import Env, { EnvKey } from "../../../Env"
import { CampaignType, ControlAlgorithmStrategy, OptimisationStrategy, Platform } from "../../../generated/graphql"
import { useCampaignParams } from "../../../hooks/navigationHooks"
import { useYouTubeEnabled } from "../../../hooks/useYouTubeEnabled"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import { UserProfile } from "../../../models/AuthState"
import { enumToLabelValueArray } from "../../../utils/enumUtils"
import ManageCampaignData, { Step1Data } from "./ManageCampaignData"

interface FormValues {
  strategy: OptimisationStrategy
  controlAlgorithmStrategy?: ControlAlgorithmStrategy
  isAutoApproved: boolean
  margin: string
  minMargin?: string
  maxMargin?: string
  campaignType?: CampaignType
}

const optimisationStrategyOptions = [
  {
    label: "CaQrtz003PPacing - Creates a Logit model instead of QRTZ Classic model",
    value: OptimisationStrategy.CaQrtz003PPacing,
  },
  {
    label: "LogisticRegression - Latest QRTZ strategy using Logit models",
    value: OptimisationStrategy.LogisticRegression,
  },
  {
    label: "QrtzScore - QRTZ Classic with 80:20 weighting", //
    value: OptimisationStrategy.QrtzScore,
  },
  {
    label: "Qrtz001CHistory_80_20 - QRTZ Classic with 80% weighting to recent performance",
    value: OptimisationStrategy.Qrtz001CHistory_80_20,
  },
  {
    label: "CaQrtz005PTest1 - Currently unused placeholder for testing",
    value: OptimisationStrategy.CaQrtz005PTest1,
  },
  {
    label: "CaQrtz006PTest2 - Currently unused placeholder for testing",
    value: OptimisationStrategy.CaQrtz006PTest2,
  },
  {
    label: "CaQrtz007PTest3 - Currently unused placeholder for testing (Logit models)",
    value: OptimisationStrategy.CaQrtz007PTest3,
  },
  {
    label: "CaQrtz008C_59ADefault - Currently unused placeholder for QRTZ Classic V2",
    value: OptimisationStrategy.CaQrtz008C_59ADefault,
  },
  {
    label: "CaQrtz009CTest1 - Currently unused placeholder for testing (QRTZ Classic V2 models)",
    value: OptimisationStrategy.CaQrtz009CTest1,
  },
  {
    label: "CaQrtz010CTest2 - Currently unused placeholder for testing (QRTZ Classic V2 models)",
    value: OptimisationStrategy.CaQrtz010CTest2,
  },
  {
    label: "CaQrtz011CTest3 - Currently unused placeholder for testing (QRTZ Classic V2 models)",
    value: OptimisationStrategy.CaQrtz011CTest3,
  },
  {
    label: "Playbook - DO NOT USE - this strategy is no longer in use",
    value: OptimisationStrategy.Playbook,
  },
  {
    label: "Qrtz002PPacing - DO NOT USE - this strategy is no longer in use",
    value: OptimisationStrategy.Qrtz002PPacing,
  },
  {
    label: "CaQrtz004CHistory_80_20 - DO NOT USE - this strategy is no longer in use",
    value: OptimisationStrategy.CaQrtz004CHistory_80_20,
  },
  {
    label: "ThompsonSampling - DO NOT USE - this strategy is no longer in use",
    value: OptimisationStrategy.ThompsonSampling,
  },
]

const MAX_MARGIN = 2000

const controlAlgorithmStrategyOptions = enumToLabelValueArray(ControlAlgorithmStrategy)
const allCampaignTypeOptions = enumToLabelValueArray(CampaignType)

const step1FormSchema = z
  .object({
    strategy: z.nativeEnum(OptimisationStrategy, {
      required_error: "Strategy not provided",
      invalid_type_error: "Strategy must be an OptimisationStrategy",
    }),
    controlAlgorithmStrategy: z
      .nativeEnum(ControlAlgorithmStrategy, {
        invalid_type_error: "Control Algorithm Strategy must be a ControlAlgorithmStrategy",
      })
      .optional(),
    margin: z
      .string({
        required_error: "Initial margin value not provided",
      })
      .trim(),
    minMargin: z
      .string({
        required_error: "Min margin value not provided",
      })
      .trim(),
    maxMargin: z
      .string({
        required_error: "Max margin value not provided",
      })
      .trim(),
    campaignType: z
      .string({
        required_error: "Campaign Type not provided",
        invalid_type_error: "Campaign Type must be a CampaignType",
      })
      .trim(),
  })
  .refine((schema) => Number(schema.margin) >= 0, `Initial margin must be between 0 and ${MAX_MARGIN}`)
  .refine((schema) => Number(schema.minMargin) <= Number(schema.margin), "Minimum margin must be between 0 and the initial margin")
  .refine((schema) => Number(schema.maxMargin) >= Number(schema.margin), `Maximum margin must be between the initial margin and ${MAX_MARGIN}`)

interface Step1FormProps {
  user?: UserProfile
  initialValues: FormValues
  submitAndNext: (values: FormValues) => void
}

const Step1Form: FC<Step1FormProps> = ({ user, initialValues, submitAndNext }) => {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(step1FormSchema),
  })

  const { clientId, platform } = useCampaignParams()

  const youTubeEnabled =
    useYouTubeEnabled({
      clientId: Number(clientId),
      stage: Env.requireString(EnvKey.Stage),
    }) && platform === Platform.Dv360

  const campaignTypeOptions = youTubeEnabled ? allCampaignTypeOptions : allCampaignTypeOptions.filter((option) => option.value !== CampaignType.Youtube)

  const watchStrategy = watch("strategy")

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorMessage = (errors as any)[""] && "message" in (errors as any)[""] ? (errors as any)[""].message : ""

  return (
    <>
      <Button onClick={() => navigate(-1)} isInline={true} isSecondary={true} my={3}>
        Back
      </Button>
      <form onSubmit={handleSubmit(submitAndNext)}>
        {user?.is59A && (
          <>
            <Divider />
            <SelectInput //
              options={optimisationStrategyOptions}
              name="strategy"
              label="Optimisation Strategy"
              helperText="This is the Optimisation Strategy."
              tooltip="This is the Optimisation Strategy."
              control={control}
              zIndex={9}
              width={"2xl"}
              isReadOnly
            />
            {watchStrategy === OptimisationStrategy.LogisticRegression && (
              <>
                <SelectInput //
                  options={controlAlgorithmStrategyOptions}
                  name="controlAlgorithmStrategy"
                  label="Control Algorithm Strategy"
                  helperText="This is the Control Algorithm Strategy."
                  tooltip="This is the Control Algorithm Strategy."
                  control={control}
                  zIndex={8}
                />
              </>
            )}
          </>
        )}
        <SelectInput //
          options={campaignTypeOptions}
          name="campaignType"
          label="Campaign Type"
          helperText="Please select whether the campaign is display or video."
          tooltip="This is whether the campaign is display or video."
          control={control}
          zIndex={7}
        />
        <Divider />
        <Heading paddingTop={2} size="md">
          Margin settings
        </Heading>
        <Text fontSize={"sm"} paddingBottom={4} paddingTop={2} color={"gray.600"}>
          Margin is calculated on a cost plus basis.
        </Text>
        <HStack align="flex-end" justifyContent="flex-start">
          <PercentageInput //
            name="margin"
            control={control}
            label={"Initial value"}
            max={MAX_MARGIN}
            tooltip="The initial margin value for this campaign. Set it to 0 if you do not wish to use margin."
          />
          <PercentageInput //
            name="minMargin"
            control={control}
            label={"Min"}
            max={MAX_MARGIN}
            tooltip="The minimum margin constraint for this campaign"
          />
          <PercentageInput //
            name="maxMargin"
            control={control}
            label={"Max"}
            max={MAX_MARGIN}
            tooltip="The maximum margin constraint for this campaign"
          />
        </HStack>
        <ErrorText message={errorMessage} />
        <WizardNavigateNextButton next={{ type: "submit" }} />
      </form>
    </>
  )
}

interface Step1Props extends WizardStepProps<ManageCampaignData, Step1Data> {
  currencySymbol: string
  dataFinalised: boolean
  user?: UserProfile
}

const Step1: FC<Step1Props> = ({ onNext, stepIndex, wizardData, dataFinalised, user }) => {
  // Get pre-existing data from top-level wizard, if present
  const stepData = wizardData[stepIndex] as Step1Data

  const initialValues = getInitialFormValues(stepData)

  const submitAndNext = (values: FormValues) => onNext(buildStepData(values))

  return dataFinalised ? <Step1Form user={user} initialValues={initialValues} submitAndNext={submitAndNext} /> : null
}

const getInitialFormValues = (data: Step1Data): FormValues => ({
  strategy: data?.strategy || OptimisationStrategy.LogisticRegression,
  controlAlgorithmStrategy: data?.controlAlgorithmStrategy,
  isAutoApproved: data?.isAutoApproved ?? false,
  margin: data?.margin,
  minMargin: data?.minMargin,
  maxMargin: data?.maxMargin,
  campaignType: data?.campaignType || undefined,
})

const buildStepData = (values: FormValues): Step1Data => ({
  strategy: values.strategy,
  controlAlgorithmStrategy: values.controlAlgorithmStrategy,
  isAutoApproved: values.isAutoApproved,
  margin: values.margin,
  minMargin: values.minMargin,
  maxMargin: values.maxMargin,
  campaignType: values.campaignType,
})

export default Step1
