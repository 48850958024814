import React, { FC, useEffect, useMemo, useState } from "react"
import { ApolloError } from "@apollo/client"
import { InfoIcon } from "@chakra-ui/icons"
import { Box, Center, Divider, Heading, HStack, Tooltip, VStack } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useFieldArray, useForm } from "react-hook-form"
import Button from "../../../components/Button"
import ErrorText from "../../../components/forms/ErrorText"
import KpiSelector from "../../../components/forms/KpiSelector"
import {
  buildInitialFormValues,
  buildStepData,
  FormKpi,
  FormValues,
  getKpiByName,
  KpiEntries,
  resetCpaKpi,
  resetCpcKpi,
  resetCpmKpi,
  resetCtrKpi,
  resetVcpmKpi,
} from "../../../components/forms/KpiSelector/KPI"
import { Option } from "../../../components/forms/SelectInput/SelectBaseUI"
import WizardNavigateNextButton from "../../../components/WizardNavigateNextButton"
import WizardNavigatePreviousButton from "../../../components/WizardNavigatePreviousButton"
import { KpiName, OptimisationStrategy, Platform } from "../../../generated/graphql"
import { WizardStepProps } from "../../../layouts/Wizard/WizardLayout"
import ObjectDiff from "../../../utils/ObjectDiff"
import ManageCampaignData, { Step1Data, Step2Data } from "./ManageCampaignData"
import { formValuesSchema } from "./schema"

// Initial values for each dynamically created secondary KPI
const initialSecondaryKpiValues: FormKpi = {
  name: "",
  target: { integer: "", percentage: "", monetary: "" },
  constraints: {
    min: { target: { integer: "", percentage: "", monetary: "" } },
    max: { target: { integer: "", percentage: "", monetary: "" } },
  },
  weight: "",
  pixelIds: "",
}

interface Props extends WizardStepProps<ManageCampaignData, Step2Data> {
  currencySymbol: string
  pixels: Option[]
  platform: Platform
  createLoading: boolean
  createError: ApolloError
}

const Step2: FC<Props> = ({ onBack, onNext, stepIndex, wizardData, currencySymbol, pixels, platform, createLoading, createError }) => {
  // Get pre-existing data from top-level wizard, if present
  const stepData = wizardData[stepIndex] as Step2Data

  const previousStepData = wizardData[stepIndex - 1] as Step1Data
  const isLogisticRegression = previousStepData.strategy === OptimisationStrategy.LogisticRegression
  const kpis = KpiEntries.filter((kpiEntry) =>
    isLogisticRegression
      ? kpiEntry.logisticRegressionSupported
      : kpiEntry.campaignType?.find((campaignType) => campaignType === previousStepData.campaignType) !== undefined || kpiEntry.campaignType === undefined
  )

  // Rehydrate data or provide defaults for first view
  const initialValues: FormValues = useMemo(() => buildInitialFormValues(kpis, stepData), [kpis, stepData])

  const { control, getValues, handleSubmit, watch, setValue, formState } = useForm<FormValues>({
    defaultValues: {
      ...initialValues,
    },
    resolver: zodResolver(formValuesSchema),
  })

  const {
    fields: secondaryKpis,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "secondaryKpis",
  })

  const submitAndNext = (values: FormValues) => {
    const newStepData = buildStepData(kpis, values)

    const differ = new ObjectDiff(stepData, newStepData)
    const shouldInvalidateFutureSteps = differ.hasDifferences()

    onNext(newStepData, shouldInvalidateFutureSteps)
  }

  const watchMainKpi = watch("mainKpi")
  const mainKpiName = watchMainKpi?.name ?? ""
  const [selectedMainKpi, setSelectedMainKpi] = useState<string>(mainKpiName)
  const watchSecondaryKpis = watch("secondaryKpis")
  const secondaryKpiName = watchSecondaryKpis && watchSecondaryKpis[0] && "name" in watchSecondaryKpis[0] ? watchSecondaryKpis[0]["name"] : ""

  useEffect(() => {
    if (isLogisticRegression && watchMainKpi && watchMainKpi.name && selectedMainKpi !== watchMainKpi.name && formState.isDirty) {
      const mainKpi = getKpiByName(KpiEntries, watchMainKpi.name)

      switch (mainKpi.name) {
        case KpiName.Cpm:
          resetCpmKpi(setValue)
          break
        case KpiName.Vcpm:
          resetVcpmKpi(setValue)
          break
        case KpiName.Ctr:
          resetCtrKpi(setValue)
          break
        case KpiName.Cpa:
          resetCpaKpi(setValue)
          break
        case KpiName.Cpc:
          resetCpcKpi(setValue)
          break
        default:
          break
      }

      setSelectedMainKpi(watchMainKpi.name)
    }
  }, [isLogisticRegression, setValue, watchMainKpi, watchMainKpi?.name, formState, selectedMainKpi, setSelectedMainKpi])

  const mandatorySecondaryKpisTooltipText = `You have chosen ${mainKpiName} for your main KPI which means we need you to provide a secondary KPI of ${secondaryKpiName}`

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorMessage = (formState.errors as any)[""] && "message" in (formState.errors as any)[""] ? (formState.errors as any)[""].message : ""

  return (
    <>
      <WizardNavigatePreviousButton
        previous={{
          onClick: () => onBack(buildStepData(kpis, getValues())),
          shown: true,
          text: "Back",
        }}
      />
      <form onSubmit={handleSubmit(submitAndNext)}>
        {watchMainKpi && (
          <>
            <Divider />
            <Heading pt={4} size="xl">
              {`Select your KPI${isLogisticRegression ? "" : "s"}`}
            </Heading>
            {!isLogisticRegression && (
              <Heading paddingY={4} size="lg">
                Main KPI
              </Heading>
            )}
            <Box>
              <KpiSelector //
                fieldName="mainKpi"
                kpis={kpis}
                pixels={pixels}
                platform={platform}
                zIndex={9}
                hasConstraints={true}
                hasWeight={!isLogisticRegression}
                currencySymbol={currencySymbol}
                control={control}
                errors={formState.errors}
                watch={watch}
              />
            </Box>
          </>
        )}
        {isLogisticRegression && watchSecondaryKpis && watchSecondaryKpis.length !== undefined && watchSecondaryKpis.length > 0 && (
          <>
            <HStack>
              <Heading paddingBottom={4} paddingTop={2} size="lg">
                Mandatory Secondary KPI
              </Heading>
              <Tooltip aria-label={mandatorySecondaryKpisTooltipText} label={mandatorySecondaryKpisTooltipText} placement="right-start">
                <InfoIcon color={"brand.400"} w={4} h={4} display="flex" alignSelf="flex-start" />
              </Tooltip>
            </HStack>
            <Box key={`secondaryKpi0`} paddingBottom={4}>
              <KpiSelector //
                selectDisabled={true}
                fieldName={`secondaryKpis.0` as keyof FormValues}
                kpis={kpis}
                platform={platform}
                zIndex={8}
                hasConstraints={true}
                currencySymbol={currencySymbol}
                control={control}
                errors={formState.errors}
                watch={watch}
              />
            </Box>
          </>
        )}
        {!isLogisticRegression && (
          <>
            <Divider paddingTop={4} />
            <Heading paddingBottom={4} paddingTop={2} size="lg">
              Secondary KPIs
            </Heading>
            {secondaryKpis &&
              secondaryKpis.map((kpi, index) => (
                <Box key={`secondaryKpi${kpi.id}`} paddingBottom={4}>
                  <KpiSelector //
                    fieldName={`secondaryKpis.${index}` as keyof FormValues}
                    kpis={kpis}
                    pixels={pixels}
                    platform={platform}
                    zIndex={9 - (index + 1)}
                    hasConstraints={true}
                    hasWeight={true}
                    currencySymbol={currencySymbol}
                    control={control}
                    errors={formState.errors}
                    watch={watch}
                  />
                  <Button onClick={() => remove(index)}>Remove</Button>
                  <Divider color="black" paddingBottom={4} />
                </Box>
              ))}
            {secondaryKpis.length < 3 && (
              <Center paddingBottom={4}>
                <Button onClick={() => append(initialSecondaryKpiValues)} isFullWidth={true}>
                  Add Secondary KPI
                </Button>
              </Center>
            )}
          </>
        )}

        <VStack h={8} align="flex-start">
          {createError
            ? createError.graphQLErrors.map((createError) => <ErrorText message={createError.message} />)
            : errorMessage && <ErrorText message={errorMessage} />}
        </VStack>

        <WizardNavigateNextButton
          next={{
            text: "Finish",
            type: "submit",
            isDisabled: createLoading,
            isLoading: createLoading,
            shown: watchMainKpi?.name !== undefined,
          }}
        />
      </form>
    </>
  )
}

export default Step2
